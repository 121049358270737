.homeContainer {
	background: #fff;
	.homeTitle {
		justify-content: center;
		text-align: center;
		padding: 0px 0px 30px 0px;
		font-size: 1.3em;
		font-weight: bold;
	}
	.distributorChoice {
		margin-top: 50px;
		h2 {
			font-weight: bold;
			margin-top: 0;
			margin-bottom: 30px;
		}
		.claimError {
			color: red;
			text-align: center;
			&.show {
				display: block;
			}
			&.hidden {
				display: none;
			}
			p {
				font-size: 12px;
			}
		}
	}
	.homeBtnContainer {
		display: flex;
		justify-content: center;
		a {
			text-decoration: none;
		}
		.homeBtn {
			min-width: 175px;
			height: 50px;
			line-height: 20px;
			display: flex;
			justify-content: center;
			text-align: center;
			background: #f19425;
			color: #fff;
			padding: 15px;
			font-weight: 300;
			cursor: pointer;
			transition: 0.3s;
			font-weight: bold;
		}
		.homeBtn &:hover {
			background: #f6b76d;
		}

		.or {
			display: flex;
			justify-content: center;
			text-align: center;
			flex-direction: column;
			font-weight: bold;
			padding: 0px 30px 0px 30px;
		}
	}

	.needContainer {
		padding: 50px 30px 0 30px;
		ul {
			li {
				font-size: 14px;
			}
		}
	}

	.termsContainer {
		padding: 5px 30px 5px 30px;
		margin-top: 50px;
		background: #f5f4f2;
		ul {
			padding-left: 15px;
			li {
				font-size: 12px;
				line-height: 25px;
			}
		}
		.hr {
			width: 100%;
			height: 1px;
			background: #c4c3c2;
		}
		.copyRightText {
			font-size: 12px;
		}
		.bottomTermsContainer {
			display: flex;
			.bottomTermsItem {
				display: flex;
				font-size: 12px;
				transition: 0.3s;
				color: #000;
				cursor: pointer;
				&:after {
					content: '|';
					padding: 0 1em;
					display: inline-block;
					float: none;
				}
				&:last-child {
					&:after {
						content: '';
					}
				}
				&:hover {
					color: #696969;
				}
			}
		}
		a {
			display: flex;
			text-decoration: none;
		}
		.termsBtnContainer {
			display: flex;
			flex-direction: row;
			margin-bottom: 20px;
			.termsBtn {
				background: #f19425;
				color: #fff;
				text-align: center;
				font-size: 12px;
				padding: 10px 15px;
				margin: 0px 20px 0px 0px;
				transition: 0.3s;
				&:hover {
					color: #000;
				}
				@media (max-width: 768px) {
					max-width: 80%;
				}
			}
		}
	}
	.dropdownContainer {
		display: flex;
		margin-bottom: 40px;
		position: relative;
		.requiredField {
			position: absolute;
			font-size: 12px;
			bottom: -30px;
		}
		.fieldHeader {
			position: absolute;
			font-size: 15px;
			top: -20px;
		}
		> span {
			> span {
				padding-left: 5px;
			}
		}
	}

	.choiceContainer {
		margin-top: 50px;
	}

	.startOverContainer {
		display: flex;
		justify-content: center;
		padding: 20px 0;
		margin-top: 20px;
		.startOver {
			font-size: 25px;
			font-weight: bold;
			color: #f19425;
			cursor: pointer;
		}
	}
}

@media only screen and (min-width: 200px) {
	.dropdownContainer {
		.requiredField,
		.fieldHeader {
			left: 0;
		}
	}
}

@media only screen and (min-width: 738px) {
	.dropdownContainer {
		.requiredField,
		.fieldHeader {
			left: 175px;
		}
	}
}

@media only screen and (min-width: 992px) {
	.dropdownContainer {
		.requiredField,
		.fieldHeader {
			left: 285px;
		}
	}
}

@media only screen and (min-width: 1200px) {
	.dropdownContainer {
		.requiredField,
		.fieldHeader {
			left: 385px;
		}
	}
}

@media only screen and (max-width: 741px) {
	.homeContainer {
		height: calc(100vh - 139px);
		.homeBtnContainer {
			flex-direction: column;
			.or {
				padding: 30px 0px 30px 0px;
			}
		}
		.termsContainer {
			padding: 5px 10px 5px 10px;
		}
	}
}
