.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    width: 500px;
}

@media only screen and (max-width: 1024px) {
    .dropzone {
        width: 300px;
    } 
}

.dropzone:hover, .dropzone:active {
    border-color: '#2196f3';
}

.thumbs-container {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}

.thumb {
    display: inline-flex;
    border-radius: 2;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
}

.thumb-inner {
    display: flex;
    min-width: 0;
    overflow: hidden;
}

.thumbs-container .file {
    border: 1px solid gray;
    padding: 3px;
    margin: 10px 0;
}

.thumbs-container .file .name {
    display: inline-block;
    width: calc(100% - 40px);
    font-size: 14px;
}

.thumbs-container .file .delete {
    float: right;
    margin-top: -2px;
    padding: 0 5px;
}

.thumbs-container .file .delete:hover {
    cursor: pointer;
    color: red;
}

.img {
    display: block;
    max-width: 180px;
    height: 100%;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}