.app-header {
	position: relative;
	background-color: #f19425;
}
.app-header .app-logo {
	background: transparent url('../../assets/images/common/logo-basf.svg')
		no-repeat;
	background-repeat: no-repeat;
	overflow: hidden;
	display: block;
	float: left;
	width: 87px;
	height: 0;
	margin: 12px 0 11px;
	padding-top: 31px;
	background-size: 100% auto;
}
.bannerLink {
	height: 100%;
	width: 170px;
	z-index: 9998;
	position: absolute;
}

.app-header,
.app-logo,
.bannerLink {
	height: 54px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.app-logo {
		height: 80px;
		width: 440px;
		background-size: cover;
		display: inline-block;
	}
}
@media (min-width: 768px) {
	.container {
		width: 750px;
	}
	.app-header,
	.app-logo,
	.bannerLink {
		height: 54px;
	}
}

@media (min-width: 992px) {
	.app-header .app-logo {
		width: 125px;
		margin: 22px 0;
		padding-top: 46px;
	}
	.container {
		width: 970px;
	}
	.app-header,
	.app-logo,
	.bannerLink {
		height: 90px;
	}
}

@media (min-width: 1200px) {
	.container {
		width: 1170px;
	}
	.app-header,
	.app-logo,
	.bannerLink {
		height: 90px;
	}
}

.container {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}
