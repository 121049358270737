* {
	box-sizing: border-box;
}
html {
	-webkit-text-size-adjust: 100%;
}
body {
	/* max-width: 1700px; */
	/* background-image: url(./assets/images/common/siteBG.gif);  */
	margin: auto;
	padding: 0;
	font-size: 15px;
	font-weight: 300;
	font-family: HelveticaNeueLTStd-Lt, sans-serif;
	color: #414141;
	background-color: #fff;
}
p {
	font-size: 18px;
}
h1 {
	display: flex;
	font-size: 24px;
	font-weight: 300;
}
h2 {
	display: flex;
	font-size: 22px;
	font-weight: bold;
}
h5 {
	display: flex;
	font-size: 12px;
	font-weight: 300;
}
h1,
// h2,
h3,
h4,
h5,
h6 {
	font-family: HelveticaNeueLTStd-Md, sans-serif;
	color: #f19425;
}

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.required {
	font-size: 10px;
	padding-top: 5px;
}

.text-left {
	text-align: left;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.orange {
	color: #f19425;
}

.grey {
	color: #414141;
}

.justify-center {
	justify-content: center;
}

/* INPUT TEXT FIELDS */
.inputContainer {
	display: flex;
	padding: 10px 0px;
	.input {
		width: 50%;
		flex: 1;
		input {
			width: 100%;
			max-width: 375px;
			font-size: 14px;
		}
	}
	&.inputBorder {
		text-align: center;
		padding-bottom: 50px;
		border-bottom: 1px solid #e3e3e3;
	}
}

.pageTitle {
	background-color: #dcdcdc;
	padding: 15px;
	border-bottom: 4px solid #f19425;
	margin-bottom: 15px;
	h2 {
		color: #333333;
		font-size: 32px;
		margin: 0;
	}
}
@media only screen and (max-width: 738px) {
	.inputContainer {
		padding: 0px;
		flex-direction: column;
		.input {
			width: 100%;
			padding: 5px 0px;
			input {
				width: 100%;
				max-width: unset;
			}
		}
	}
}

/* PAGE LAYOUT */
.mainContainer {
	width: 100%;
	display: flex;
	//padding-bottom: 53px; //this is for the fixed footer.

	.leftContainer {
		width: 220px;
		display: flex;
		background: #fff;
	}
	.leftContainer.hidden {
		display: none;
	}
	.rightContainer {
		display: flex;
		flex-direction: column;
		flex: 1;
		flex-direction: column;
		background: #fff;
	}
}

.mLeft {
	margin-left: 220px;
}

// @media only screen and (min-width: 1025px) {
//     .mainContainer {
//         margin-top: 40px;
//     }
// }

.infoHeader {
	margin: 20px 0px 20px 505px !important;
}

@media only screen and (max-width: 1024px) {
	.mainContainer {
		flex-direction: column;
		.leftContainer {
			width: 100%;
		}
	}
	.mLeft {
		margin-left: auto;
	}
	// .mainContainer {
	//     margin-top: 15px;
	// }
	.infoHeader {
		margin: 12px 10px !important;
	}
}

@media only screen and (max-width: 615px) {
	.scroll {
		overflow: scroll;
	}
	// .mainContainer {
	//     margin-top: 8px;
	// }
}

/* KENDO UI OVERWRITE STYLES */
.k-dropdown {
	margin-top: 20px !important;
}
.k-widget,
.k-textbox-container,
.k-dropdown, 
.k-dropdowntree
{
	width: 100% !important;
	max-width: 375px;
}

.searchContainer .k-textbox-container {
	max-width: none;
	margin-top: unset;
}
.k-dropdown-wrap.k-state-default {
	color: #656565;
	/* background-color: #f6f6f6; */
	border-color: rgba(0, 0, 0, 0.42);
	font-size: 14px;
}

.k-list .k-item.k-state-selected,
.k-item .k-state-selected,
.k-list .k-item:hover.k-state-selected {
	color: #fff;
	background: #f19425;
}
.k-textbox:hover,
.k-textbox.k-state-hover,
.k-textarea:hover,
.k-input.k-textbox.k-state-hover .k-textbox:focus,
.k-textbox.k-state-focus,
.k-textarea:focus,
.k-textarea.k-state-focus,
.k-input.k-textbox.k-state-focus,
.k-datepicker .k-picker-wrap.k-state-focused,
.k-dropdown-wrap.k-state-focused {
	border-color: #f19425;
}
.k-textbox::selection,
.k-textarea::selection,
.k-input.k-textbox::selection,
.k-textbox::selection,
.k-widget ::selection,
.k-block ::selection,
.k-panel ::selection,
.k-textbox-container::after {
	background-color: #f19425 !important;
}
.k-calendar-header,
.k-today,
.k-content .k-scrollable th:hover,
.k-state-focused label {
	color: #f19425 !important;
}
.k-textbox-container > .k-label {
	font-size: 14px;
}

.k-textbox:focus,
.k-textbox.k-state-focus,
.k-textarea:focus,
.k-textarea.k-state-focus,
.k-input.k-textbox.k-state-focus {
	border-color: #f19425;
}

.k-state-selected span.k-link {
	background: #f19425 !important;
}

.k-dropdown-wrap > .k-input {
	padding-bottom: 0px;
	padding-left: 5px;
}

.k-textbox:disabled,
.k-textarea:disabled {
	border-style: solid;
}

.k-multiselect {
	font-family: 'WebComponentsIcons';
}

/*.k-multiselect::after {
	content: '\e006';
	position: absolute;
	top: 30%;
	right: 5px;
	font-size: 16px;
	color: #acacac;
}*/

.k-multiselect-wrap > ul {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.k-multiselect-wrap > .k-i-close::before {
	position: absolute;
	right: 15px;
}

.k-multiselect-wrap.k-floatwrap > ul > li {
	width: 47.5%;
}

@media only screen and (max-width: 738px) {
	.k-widget,
	.k-textbox-container {
		width: 100%;
		max-width: 100%;
	}
}

.page-width {
	max-width: 2000px;
	margin: 0 auto;
}

// @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
.k-textbox-container.k-state-focused label.k-label,
.k-textbox-container:not(.k-state-empty) label.k-label {
	top: 0;
}
// }
