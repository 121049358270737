.contactContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 20px;

	.no-btm-margin {
		margin-bottom: 0;
	}

	.contact-title {
		font-size: 24px;
		font-weight: bold;
	}

	.iAmContainer {
		width: 100%;
		display: flex;
		padding: 10px 0px 10px 0px;
		.iAm {
			width: 200px;
			height: 25px;
			line-height: 25px;
			// margin-right: 35px;
			font-weight: 300;
			display: flex;
			font-size: 14px;
			cursor: pointer;
			justify-content: center;
			background: gainsboro;
			&.active {
				background: #f19425;
				color: #fff;
			}
		}
	}

	// .checkBoxContainer {
	// 	padding-top: 50px;
	// }

	.PremiumStyle {
		padding: 20px 10px;
		font-weight: bold;
	}
}

.productContainer,
.inputContainer {
	.input {
		input {
			font-size: 13px;
		}
		.phone-examples {
			font-size: 11px;
			margin-bottom: 0;
		}
	}
}

@media only screen and (max-width: 738px) {
	.contactContainer {
		padding-left: 10px;
	}
}
