.SpinnerContainer {
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.5;
    z-index: 99;
    position: fixed;
    left: 0;
    top: 0;
    .Spinner {
        width: 300px;
        height: 300px;
        left: calc(50vw - 150px);
        top: calc(50vh - 150px);
        position: fixed;
    }
}