.Modal-Backdrop {
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background-color: black;
    opacity: 0.5;
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
}
.Modal {
    width: 600px;
    height: 190px;
    z-index: 99;
    background-color: white;
    position: fixed;
    left: calc(50vw - 300px);
    top: calc(50vh - 100px);
    border-radius: 15px;
    .Modal-Title {
        width: 100%;
        text-align: center;
        padding: 10px;
        background-color: #f19425;
        border-radius: 15px 15px 0 0;
        font-weight: 300;
        color: white;
    }
    .Modal-Text {
        text-align: center;
        font-size: 18px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .Modal-Buttons {
        width: 100%;
        text-align: center;
        button {
            min-width: 100px;
            background-color: #f19425;
            color: #fff;
            padding: 10px;
            margin: 0px 10px;
            font-size: 16px;
            font-weight: 300;
            border: none;
            cursor: pointer;
            text-align: center; 
            &.btnCancel {
                background-color: grey;
            }
            &.btnOkay {
                background-color: #f19425;
            }
        }
    }
}





@media only screen and (max-width: 738px) {
    .Modal {
        width: 90%;
        height: 190px; 
        left: 5%;  
        .Modal-Text {
            font-size: 18px;
        }
    }
}