.error-panel {
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	.errorLine{
		display: flex;
		width: 100%;
		background-color: #f19425;
		color: #fff;
		margin-bottom: 2px;
		padding: 10px 10px 10px 70px;
		font-weight: 300;
		background-image: url("../../assets/images/error.png");
		background-repeat: no-repeat;
		background-size: 25px;
		background-position: left;
		background-position-x: 20px;
		font-size: 14px;
	}
}