.fieldInfoContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 20px;

	a {
		text-decoration: none;
	}

	.searchContainer {
		width: 100%;
		display: flex;
		padding: 20px 0px;
		.searchPostal {
			height: 40px;
			display: flex;
			flex: 1;
			font-size: 14px;
			margin-right: 20px;
			background: url('../../assets/images/common/mag.png') no-repeat
				scroll 7px 7px;
			background-position: calc(100% - 10px);
			padding: 10px;
		}
		.searchBtn {
			width: 200px;
			background: #f19425;
			color: #fff;
			text-align: center;
			display: flex;
			justify-content: center;
			transition: 0.3s;
			font-size: 18px;
			font-weight: 300;
			border: none;
			cursor: pointer;
			height: 45px;
			margin-top: 20px;
			padding: 10px;
		}
		@media only screen and (max-width: 1024px) {
			.searchBtn {
				width: 90px;
			}
		}
	}

	.documentContainer {
		display: flex;
		flex-direction: row;
		.attachContainer {
			margin: 0px 40px 20px 0px;
			.attachText {
				font-size: 14px;
				margin-bottom: 5px;
				margin: 15px 0px 15px;
			}
			.attachBtn {
				width: 150px;
				height: 30px;
				background: #f19425;
				color: #fff;
				display: flex;
				justify-content: center;
				font-size: 14px;
				text-align: center;
				flex-direction: column;
				cursor: pointer;
			}
		}
	}

	.claimCalculator {
		.input {
			padding: 5px;
		}
	}

	.productHerbicide {
		padding: 20px 0px;
		font-size: 14px;
		.productNameText {
			color: rgba(0, 0, 0, 0.38);
			font-size: 11px;
			padding-bottom: 10px;
		}
		.k-dropdown {
			margin: 0;
		}
	}

	.noProductText {
		color: rgba(0, 0, 0, 0.38);
		font-size: 11px;
		padding-bottom: 5px;
	}

	.dropDownTextContainer {
		position: relative;
		padding-top: 10px;
		.reasonText {
			color: rgba(0, 0, 0, 0.38);
			font-size: 11px;
			position: absolute;
			margin-top: 5px;
		}
	}

	.estimatedClaim {
		span {
			font-size: 28px;
			padding-left: 20px;
			color: #f19425;
			line-height: 27px;
		}
	}

	.redrillingContainer {
		width: 100%;
		display: flex;
		padding: 10px 0px 10px 0px;
		.redrilling {
			width: 200px;
			height: 45px;
			line-height: 45px;
			margin-right: 35px;
			font-weight: 300;
			display: flex;
			font-size: 14px;
			cursor: pointer;
			justify-content: center;
			background: gainsboro;
			&.active {
				background: #f19425;
				color: #fff;
			}
		}
	}

	.k-textbox,
	.k-textarea,
	.k-input.k-textbox {
		font-size: 14px;
	}

	.addNewFieldBtn {
		width: 200px;
		height: 45px;
		line-height: 45px;
		font-size: 14px;
		font-weight: 300;
		background: #f19425;
		color: #fff;
		text-align: center;
		cursor: pointer;
		display: inline-block;
		margin-right: 20px;
	}

	.removeFieldBtn {
		width: 200px;
		height: 45px;
		line-height: 45px;
		font-size: 14px;
		font-weight: 300;
		background: grey;
		color: #fff;
		text-align: center;
		cursor: pointer;
		display: inline-block;
	}

	.inputWithUnits {
		display: flex;
		.k-textbox-container {
			width: 215px !important;
		}
		.k-dropdown {
			width: 160px !important;
			margin-left: 5px;
		}
		.seedUnits {
			margin-top: 40px;
			font-size: 14px;
			font-weight: 300;
		}
		.seedRateUnit {
			margin-top: 29px;
			font-size: 14px;
		}
	}
	@media only screen and (max-width: 738px) {
		.inputWithUnits {
			.k-textbox-container {
				width: 60%;
			}
			.k-dropdown {
				width: 40%;
			}
		}
	}

	.datePickerContainer {
		display: flex;
		padding-top: 20px;
		padding-bottom: 20px;
		.datePickerInput {
			display: flex;
			flex: 1;
			flex-direction: column;
			.drillText {
				color: rgba(0, 0, 0, 0.38);
				font-size: 11px;
				padding-bottom: 5px;
			}
			.datePicker {
				margin-top: 0px;
			}
		}
	}

	.failedHaReadOnly {
		line-height: 37px;
		line-height: 27px;
		border-bottom: 1px solid;
		width: 90%;
		max-width: 375px;
		span {
			font-size: 11px;
			color: rgba(0, 0, 0, 0.38);
		}
		.failedHaSet {
			div {
				font-size: 14px;
			}
		}
		.failedHaNotSet {
			color: #f31700;
			line-height: 45px;
			margin-top: 29px;
			font-size: 14px;
			margin-top: 10px;
		}
	}
	@media only screen and (max-width: 738px) {
		.datePickerContainer {
			flex-direction: column;
		}
		.failedHaReadOnly {
			width: 100%;
			max-width: unset;
		}
		.addNewFieldBtn,
		.removeFieldBtn {
			width: 100%;
		}
		.inputWithUnits {
			input {
				width: 100% !important;
			}
		}
	}
}

@media only screen and (max-width: 738px) {
	.fieldInfoContainer {
		padding-left: 10px;
		.documentContainer {
			.attachContainer {
				margin: 0px;
				.attachText {
				}
			}
		}
	}
}

.required-field.error {
	/*border-color: #f31700;*/
	border-color: rgba(0,0,0,0.42);
	color: rgb(65, 65, 65);
	.k-multiselect-wrap,
	.k-dropdown-wrap,
	.k-dateinput-wrap,
	.k-picker-wrap {
		/*border-color: #f31700;*/
	}
	+ label.k-label {
		// span.k-input {
		color: rgb(65, 65, 65);
	}
}

// .required-field.error {
// 	.k-dateinput-wrap {
// 		input {
// 			color: #f31700;
// 		}
// 	}
// }

.required-field.good {
	border-color: rgba(0, 0, 0, 0.42);
	color: rgb(65, 65, 65);
	.k-multiselect-wrap,
	.k-dropdown-wrap,
	.k-dateinput-wrap,
	.k-picker-wrap {
		border-color: rgba(0, 0, 0, 0.42);
	}
	+ label.k-label,
	span.k-input {
		color: rgb(65, 65, 65);
	}
}

.required-field.good {
	.k-dateinput-wrap {
		input {
			color: rgb(65, 65, 65);
		}
	}
}

.k-textbox-container input.required-field.error {
	/*border-color: #f31700;*/
}

.k-textbox-container input.required-field.good {
	border-color: rgba(0, 0, 0, 0.42);
}

// .productHerbicide .productNameText.error,
// .datePickerContainer .datePickerInput .drillText.error {
// 	color: #f31700;
// }

.productHerbicide .productNameText.good,
.datePickerContainer .datePickerInput .drillText.good {
	color: rgb(65, 65, 65);
}

.k-textbox-container {
	input[name='failedArea'] + label.k-input {
		color: rgb(65, 65, 65);
	}
}


.header-with-icon {
	/*display: flex;*/
	justify-content: space-between;
	align-items: center;
}

.header-icon {
	display: block;
	height: 18px;
	width: 18px;
	text-align: center;
	cursor: pointer;
	font-family: georgia;
	border-radius: 5px;
	text-transform: none;
	font-size: 15px;
	background-color: #f1f1f1;
	color: #555555;
	line-height: 18px;
	border: 1px solid #676767;
	/*flex: 0 0 18px;*/
	float:left;
	margin: 0 5px;
}

.header-tooltip {
	background-color: #fff;
	color: #454545;
	border: 1px solid #65ac1e;
}


.modified-tooltip-text {
	font-size: 15px;	
}

.header-text {
	/*flex: 1 1 25%;*/
	float:left;
	margin-right: 5px;
	font-size: 15px;
}

