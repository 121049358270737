@import url('https://fonts.googleapis.com/css?family=Fira+Mono:400,700');

$s-xsmall: 0.625em; // 10px
$s-small: 1.2em;
$border-width: 1px;
$c-primary: #f19425;
$t-base: 0.4s;
$t-fast: 0.2s;
$e-in: ease-in;
$e-out: cubic-bezier(.11,.29,.18,.98);

.checkbox {
  + .checkbox {
    margin-top: $s-small;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__symbol {
    display: inline-block;
    display: flex;
    margin-right: $s-small;
    border: $border-width solid $c-primary;
    position: relative;
    // overflow: hidden;
    width: 1.5625em;
    min-width: 1.5625em;
    height: 1.5625em;
    min-height: 1.5625em;
    &:after {
      content: '';
      position: absolute;
      top: 0.53125em;
      left: 0.53125em;
      width: 0.25em;
      height: 0.25em;
      background-color: rgba($c-primary, .2);
      opacity: 0;
      border-radius: 3.125em;
      transform: scale(1);
      transform-origin: 50% 50%;
    }
    transition: box-shadow $t-base $e-out, background-color $t-base; // for focus state
    box-shadow: 0 0 0 0 rgba($c-primary,0.1); // for focus state
  }
  .icon-checkbox {
    width: 1em;
    height: 1em;
    margin: auto;
    fill: none;
    stroke-width: 3;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    color: $c-primary;
    display: inline-block;
    path {
      transition: stroke-dashoffset $t-fast $e-in;
      stroke-dasharray: 29px, 31px;
      stroke-dashoffset: 30px;
    }
  }

  &__textwrapper {
    margin: 0;
  }

  // Interactivity
  &__trigger {
    &:checked + .checkbox__symbol {
      &:after { animation: ripple 1.5s $e-out; }
      .icon-checkbox  {
        path {
          transition: stroke-dashoffset $t-base $e-out;
          stroke-dashoffset: 0px;
        }
      }
    }
    &:focus + .checkbox__symbol {
      box-shadow: 0 0 0 0.25em rgba($c-primary,0.2);
    }
  }
}
 
@keyframes ripple{
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(20)
  }
}

// Helpclasses
:root .visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}  
