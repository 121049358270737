.subHeader {
	width: 100%;
	display: flex;
	font-size: 27px;
	padding-top: 10px;
	padding-bottom: 10px;
	//background: gainsboro;
	background: white;
	//border-bottom: 5px solid #f19425;
	//border-bottom: 2px solid #d9d9d9;
	font-weight: 300;
	text-align: center;
}