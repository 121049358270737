.navBar {
	width: 220px;
	display: flex;
	margin: 0px;
	background-color: white; 
	z-index: 10;
	font-size: 14px;
	transition: 0.4s;
	padding-left: 20px;
	margin-block-start: 0.67em;
    
    .completeness {
        float: right;
        margin: 10px 10px;
    }
	
	#navItemsContainer {
		display: flex;
		width: 100%;
		flex-direction: column; 
		.navItem {
			color: #000;
			font-weight: 300;
			text-decoration: none;
			line-height: 45px; 
			transition: 0.3s;
			width: 100%;
			list-style-type: none;
			margin: 0;
			padding-left: 10px;
			padding-right: 10px;
			border-bottom: 10px solid #ffffff;
			cursor: unset;

			&.active {
				background: #f5f4f2;
			}   
		} 
	}
} 

#mobileNavItemsContainer { 
	width: 100%;
	max-height: 140px;  
	display: flex;
    flex-direction: column;
	background: #fff;
	color: #ffffff;
	overflow:hidden; 
	-webkit-transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
	transition: max-height 0.5s ease 0s, color 0.5s ease 0s; 
	box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.5);
	&.collapsed {
		max-height: 0px;
		-webkit-transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
		transition: max-height 0.5s ease 0s, color 0.5s ease 0s;
	}   
	a {
		color: #000;
		font-weight: 300;
		text-decoration: none;
		padding-left: 10px;
		padding-right: 10px;
		line-height: 45px; 
		width: 100%;
		list-style-type: none;
		margin: 0;
		background: #fff; 
		transition: 0.3s;
		&:hover {
			color: #f6b76d;;
		}
		&.active {
			color: #f6b76d;;
		}   
	}
}  

  
.mobile-bars {
	display: none;
	margin: 0px;
	background-color: white;
	width: 100%;
	z-index: 10;
	font-size: 14px;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;  
	padding-top: 10px;
	padding-bottom: 10px; 
	.bars {
		height: 25px;
		background-image: url(../../assets/images/common/ddImage.png);
		background-repeat: no-repeat;
		margin-left: 10px;
		cursor: pointer; 
	} 
	.close-bars {
		cursor: pointer;
		margin-left: 21px;
		font-size: 24px; 
		animation: fadein 1s;
		-moz-animation: fadein 1s; 
		-webkit-animation: fadein 1s;  
		-o-animation: fadein 1s; 
	}
}

@keyframes fadein {	from {opacity:0;}	to {opacity:1;}}
@-moz-keyframes fadein {from {opacity:0;}	to {opacity:1;}}
@-webkit-keyframes fadein { from {opacity:0;}to {opacity:1;}}
@-o-keyframes fadein {	from {opacity:0;}to {opacity: 1}}


.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	max-width:220px;
}  
  
@media only screen and (max-width: 1024px) { 
	.navBar { 
		display:none;
		height: 45px;
		padding: 0px;
		box-shadow: 0px 0px 3px 0px grey;
	}
	.mobile-bars {
		display: block;
	}
	#navItemsContainer {
		display: none !important;
	}
	.sticky {
		max-width: unset;
	}
}


@media only screen and (max-width: 738px) {
	#navItemsContainer {
		position: absolute;
		margin-top: 45px;
		z-index: 11;
	}  
} 