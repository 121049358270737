.submitClaimContainer {
    display:flex;
    flex-direction: column;
    padding-left: 20px;
    .checkBoxContainer {
        display: flex;
        padding: 10px 0px 30px 0px;
    }
}

@media only screen and (max-width: 738px) {
    .submitClaimContainer {
        padding-left: 10px;
    }
}