.footer {
	width: 100%;
	background-color: #f5f4f2;  
	justify-content: flex-end; 
    padding: 10px;
    margin-top: 20px;

	/* position: fixed; */
    /* max-width: 1700px; */
    bottom: 0;
    z-index: 10;
	.button {
		min-width: 100px;
		background-color: #f19425;
		color: #fff;
		padding: 10px;
		font-size: 16px;
		font-weight: 300;
        cursor: pointer;
        text-align: center;
		&.back {
			background-color: grey;
			color: #fff; 
        }
        &.cancel {
            background-color: grey;
            color: #fff;
        }
        &.next {
            float: right;
            margin-left: 10px;
        }
	}
	.footer-link {
		color: white;
        text-decoration: none;
        &.left {
            float: left;
        }
        &.right {
            float: right;
            margin-left: 5px;
        }
	}
}