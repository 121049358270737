.successContainer {
	padding-left: 20px;
	.printPDFContainer {
		display: flex;
		width: 200px;
		cursor: pointer;
		.greenArrow {
			display: flex;
			width: 30px;
			height: 30px;
			background: url('../../assets/images/common/arrow.png') no-repeat
				scroll 11px 8px;
			border-radius: 50%;
			background-color: #f19425;
		}
		.greenText {
			display: flex;
			color: #f19425;
			line-height: 32px;
			padding-left: 8px;
			font-weight: 300;
			font-size: 16px;
		}
	}
	a {
		text-decoration: none;
	}
	.proccessBtn {
		width: 270px;
		height: 45px;
		line-height: 45px;
		display: flex;
		padding: 0px 20px;
		color: #fff;
		background-color: #f19425;
		justify-content: center;
		margin-top: 40px;
		font-size: 15px;
		font-weight: 300;
		cursor: pointer;
	}
	.closeBrowser {
		display: flex;
		margin: auto;
		padding-top: 10px;
		font-size: 13px;
		justify-content: center;
		color: #f19425;
		text-decoration: underline;
		cursor: pointer;
	}

	.submit-p-container {
		padding: 20px;
		background-color: #dcdcdc3b;
		border-radius: 20px;
		margin: auto;
		display: flex;
		flex-direction: column;
		.submit-p {
			display: flex;
			line-height: 35px;
			font-size: 14px;
			.text {
				width: 320px;
				display: flex;
			}
			.answer {
				display: flex;
				color: #f19425;
				flex-direction: column;
				.product {
					font-size: 14px;
					margin: 0;
					line-height: 25px;
				}
			}
		}
	}
}

@media only screen and (max-width: 738px) {
	.successContainer {
		padding-left: 10px;
		.submit-p-container {
			background: #dcdcdc26;
			border-radius: 0px;
			padding: 5px;
			.submit-p {
				.text {
					width: 175px;
				}
			}
		}
	}
}
